import React from 'react';
import { Helmet } from 'react-helmet';

import NavigationBar from '../NavigationBar';
import IEWarning from '../IEWarning';
import { createPreviewPath } from '../../helper';

class NavHead extends React.Component {
  /* eslint-disable default-case */
  static getFontSize(style, size) {
    if (style === 'h1') {
      switch (size) {
        case 'Small':
          return '3.4rem';
        case 'Normal':
          return '4.0rem';
        case 'Large':
          return '5.0rem';
      }
    }

    if (style === 'h2') {
      switch (size) {
        case 'Small':
          return '2.55rem';
        case 'Normal':
          return '3.0rem';
        case 'Large':
          return '3.75rem';
      }
    }

    if (style === 'h3') {
      switch (size) {
        case 'Small':
          return '1.7rem';
        case 'Normal':
          return '2.0rem';
        case 'Large':
          return '2.5rem';
      }
    }

    if (style === 'h4') {
      switch (size) {
        case 'Small':
          return '1.275rem';
        case 'Normal':
          return '1.5rem';
        case 'Large':
          return '1.875rem';
      }
    }

    if (style === 'h5') {
      switch (size) {
        case 'Small':
          return '1.19rem';
        case 'Normal':
          return '1.4rem';
        case 'Large':
          return '1.75rem';
      }
    }

    if (style === 'p' || style === 'h6') {
      switch (size) {
        case 'Small':
          return '0.85rem';
        case 'Normal':
          return '1.0rem';
        case 'Large':
          return '1.25rem';
      }
    }

    if (style === 'button') {
      switch (size) {
        case 'Small':
          return '0.85rem';
        case 'Normal':
          return '1.0rem';
        case 'Large':
          return '1.25rem';
      }
    }

    return '1.0rem';
  }

  static getFontSizeAlt(style, size) {
    if (style === 'h1') {
      switch (size) {
        case 'Small':
          return '1.75rem';
        case 'Normal':
          return '2rem';
        case 'Large':
          return '2.125rem';
      }
    }

    if (style === 'h2') {
      switch (size) {
        case 'Small':
          return '1.625rem';
        case 'Normal':
          return '1.875rem';
        case 'Large':
          return '2rem';
      }
    }

    if (style === 'h3') {
      switch (size) {
        case 'Small':
          return '1.375rem';
        case 'Normal':
          return '1.5rem';
        case 'Large':
          return '1.625rem';
      }
    }

    if (style === 'h4') {
      switch (size) {
        case 'Small':
          return '1.25rem';
        case 'Normal':
          return '1.375rem';
        case 'Large':
          return '1.5rem';
      }
    }

    if (style === 'h5') {
      switch (size) {
        case 'Small':
          return '1.125rem';
        case 'Normal':
          return '1.25rem';
        case 'Large':
          return '1.375rem';
      }
    }

    if (style === 'h6') {
      switch (size) {
        case 'Small':
          return '0.875rem';
        case 'Normal':
          return '1.0rem';
        case 'Large':
          return '1.125rem';
      }
    }

    return '1.0rem';
  }
  /* eslint-enable default-case */

  static getCode(node) {
    const script = document.createElement('script');
    script.innerHTML = node.innerHTML;
    if (node.attributes && node.attributes.length > 0) {
      Array.prototype.forEach.call(node.attributes, (attr) => {
        script.setAttribute(attr.nodeName, attr.nodeValue);
      });
    }

    return script;
  }

  constructor(props) {
    super(props);

    const head = [];
    const before = [];
    const start = [];

    if (typeof document !== 'undefined') {
      const parser = new DOMParser();

      this.props.pageContext.seo.trackers.forEach((tracker) => {
        if (tracker.active === true) {
          if (
            tracker.visibility.visible === 'sitewide' ||
            (tracker.visibility.visible === 'all' &&
              !tracker.visibility.pages.includes(this.props.pageContext.page._id.valueOf())) ||
            (tracker.visibility.visible === 'only' &&
              tracker.visibility.pages.includes(this.props.pageContext.page._id.valueOf()))
          ) {
            const doc = parser.parseFromString(`<body>${tracker.snippet}</body>`, 'text/html');
            const snippet = tracker.snippet.toLowerCase();
            if (snippet.startsWith('<?xml')) {
              const xmls = doc.body.childNodes;
              before.push(...xmls);
            } else {
              const links = doc.getElementsByTagName('link');
              const styles = doc.getElementsByTagName('style');
              const metas = doc.getElementsByTagName('meta');

              Array.prototype.forEach.call(metas, (node) => {
                const attributes = {};
                if (node.attributes.length > 0) {
                  Array.prototype.forEach.call(node.attributes, (attribute) => {
                    attributes[attribute.nodeName] = attribute.nodeValue;
                  });
                }

                // eslint-disable-next-line react/jsx-props-no-spreading
                head.push(<meta {...attributes} />);
              });

              Array.prototype.forEach.call(links, (node) => {
                const attributes = {};
                if (node.attributes.length > 0) {
                  Array.prototype.forEach.call(node.attributes, (attribute) => {
                    attributes[attribute.nodeName] = attribute.nodeValue;
                  });
                }

                // eslint-disable-next-line react/jsx-props-no-spreading
                head.push(<link {...attributes} />);
              });

              Array.prototype.forEach.call(styles, (node) => {
                const attributes = {};
                if (node.attributes.length > 0) {
                  Array.prototype.forEach.call(node.attributes, (attribute) => {
                    attributes[attribute.nodeName] = attribute.nodeValue;
                  });
                }

                // eslint-disable-next-line react/jsx-props-no-spreading
                head.push(<style {...attributes}>{node.innerHTML}</style>);
              });

              const nodes = doc.body.childNodes;
              Array.prototype.forEach.call(nodes, (node, index) => {
                if (
                  tracker.placement === 'start' &&
                  (node.nodeName === 'P' ||
                    node.nodeName === 'DIV' ||
                    node.nodeName === 'SCRIPT' ||
                    node.nodeName === 'NOSCRIPT')
                ) {
                  start.splice(0, 0, node);
                } else if (
                  tracker.placement === 'before' &&
                  (node.nodeName === 'P' ||
                    node.nodeName === 'DIV' ||
                    node.nodeName === 'SCRIPT' ||
                    node.nodeName === 'NOSCRIPT')
                ) {
                  before.push(node);
                } else if (tracker.placement === 'head' && node.nodeName === 'SCRIPT') {
                  const attributes = {};
                  if (node.attributes.length > 0) {
                    Array.prototype.forEach.call(node.attributes, (attribute) => {
                      attributes[attribute.nodeName] = attribute.nodeValue;
                    });
                  }

                  // eslint-disable-next-line react/jsx-props-no-spreading
                  const newElement = <script key={`script_${index}`} {...attributes}>{`${node.innerHTML}`}</script>;
                  head.push(newElement);
                } else if (tracker.placement === 'head' && node.nodeName === 'NOSCRIPT') {
                  head.push(<noscript>{`${node.innerHTML}`}</noscript>);
                }
              });
            }
          }
        }
      });
    }

    let previewImage;
    if (props.previewSrc) previewImage = props.previewSrc;
    else if (
      props.pageContext.page.previewImage !== null &&
      props.pageContext.page.previewImage !== undefined &&
      props.pageContext.page.previewImage !== ''
    ) {
      previewImage = props.pageContext.page.previewImage;
    } else if (
      props.pageContext.seo.defaultPreview !== undefined &&
      props.pageContext.seo.defaultPreview !== null &&
      props.pageContext.seo.defaultPreview !== ''
    ) {
      previewImage = props.pageContext.seo.defaultPreview;
    }

    if (!props.previewSrc && previewImage !== undefined) {
      props.pageContext.images.some((image) => {
        let found = false;
        if (image.path === previewImage || image._id.toString() === previewImage) {
          found = true;
          previewImage = createPreviewPath(image);
        }

        return found;
      });
    }

    const siteTitle = props.pageContext.seo.siteNameInTitle
      ? props.pageContext.seo.siteName || props.pageContext.sitename
      : undefined;

    const pageTitle =
      props.metaTitle || props.title || props.pageContext.page.metaTitle || props.pageContext.page.title;

    let description;
    if (props.metaDescription) description = props.metaDescription;
    else if (props.desc) description = props.desc;
    else if (props.pageContext.page && props.pageContext.page.metaDescription)
      description = props.pageContext.page.metaDescription;

    let commType = 'admins';
    if (props.comments && props.comments.commType === 'app') {
      commType = 'app_id';
    }

    let faceId;
    if (commType && props.comments && props.comments.faceId) {
      faceId = props.comments.faceId;
      if (typeof document !== 'undefined' && !document.getElementById('fb-root')) {
        const lang = props.pageContext.metaLanguage === 'fi' ? 'fi_FI' : 'en_US';
        const script = document.createElement('script');
        script.async = true;
        script.defer = true;
        script.crossOrigin = 'anonymous';
        script.src = `https://connect.facebook.net/${lang}/sdk.js#xfbml=1&version=v9.0${
          commType === 'app_id' ? `&appId=${faceId}` : ''
        }`;
        start.push(script);

        const div = document.createElement('div');
        div.id = 'fb-root';
        start.push(div);
      }
    }

    this.state = {
      head,
      before,
      title: [pageTitle, siteTitle].filter(Boolean).join(' | '),
      metaTitle: pageTitle,
      previewImage,
      description,
      start,
      faceId,
      commType,
    };
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      const fonts = new Set();

      Object.keys(this.props.pageContext.themeData.typography).forEach((key) => {
        const font = this.props.pageContext.themeData.typography[key];
        if (
          font &&
          font.name &&
          (key !== 'logo' || (key === 'logo' && (!this.props.pageContext.logo || !this.props.pageContext.logo.active)))
        )
          fonts.add(`${font.name}:${key === 'logo' ? '400,' : ''}${font.weight}`);
      });

      if (fonts.size > 0) {
        // eslint-disable-next-line global-require
        const WebFont = require('webfontloader');

        WebFont.load({
          google: {
            families: Array.from(fonts),
          },
        });
      }
    }

    this.state.before.forEach((b) => {
      if (b.nodeName === 'SCRIPT') {
        const script = NavHead.getCode(b);
        document.body.appendChild(script);
      } else document.body.appendChild(b);
    });

    this.state.start.forEach((b) => {
      if (b.nodeName === 'SCRIPT') {
        const script = NavHead.getCode(b);
        document.body.prepend(script);
      } else document.body.prepend(b);
    });
  }

  render() {
    const hasInstagram = this.props.pageContext.page.sections.some(
      (section) => (section.type === 'SOCIAL' && section.data[4].active) || section.type === 'INSTAGRAM',
    );
    const hasFacebook = this.props.pageContext.page.sections.some(
      (section) => section.type === 'SOCIAL' && section.data[2].active,
    );
    const hasTwitter = this.props.pageContext.page.sections.some(
      (section) => section.type === 'SOCIAL' && section.data[3].active,
    );

    const themeHeadingStyle = {
      fontFamily: this.props.pageContext.themeData.typography.heading.name,
      weight: this.props.pageContext.themeData.typography.heading.weight,
      lineHeight: this.props.pageContext.themeData.typography.heading.lineHeight,
      letterSpacing: this.props.pageContext.themeData.typography.heading.letterSpacing,
      textTransform: this.props.pageContext.themeData.typography.heading.textTransform,
    };

    const themeDefaultStyle = {
      fontFamily: this.props.pageContext.themeData.typography.default.name,
      weight: this.props.pageContext.themeData.typography.default.weight,
      lineHeight: this.props.pageContext.themeData.typography.default.lineHeight,
      letterSpacing: this.props.pageContext.themeData.typography.default.letterSpacing,
    };

    const themeNavigationStyle = {
      fontFamily: this.props.pageContext.themeData.typography.navigation.name,
      weight: this.props.pageContext.themeData.typography.navigation.weight,
      lineHeight: this.props.pageContext.themeData.typography.navigation.lineHeight,
      letterSpacing: this.props.pageContext.themeData.typography.navigation.letterSpacing,
      textTransform: this.props.pageContext.themeData.typography.navigation.textTransform,
    };

    const themeButtonStyle = {
      fontFamily: this.props.pageContext.themeData.typography.button.name,
      weight: this.props.pageContext.themeData.typography.button.weight,
      lineHeight: this.props.pageContext.themeData.typography.button.lineHeight,
      letterSpacing: this.props.pageContext.themeData.typography.button.letterSpacing,
      textTransform: this.props.pageContext.themeData.typography.button.textTransform,
    };

    const themeFooterStyle = {
      fontFamily: this.props.pageContext.themeData.typography.footer.name,
      weight: this.props.pageContext.themeData.typography.footer.weight,
      lineHeight: this.props.pageContext.themeData.typography.footer.lineHeight,
      letterSpacing: this.props.pageContext.themeData.typography.footer.letterSpacing,
    };

    const color0 = {
      color: this.props.pageContext.themeData.colors[0],
    };

    const color1 = {
      color: this.props.pageContext.themeData.colors[1],
    };

    const color3 = {
      color: this.props.pageContext.themeData.colors[3],
    };

    const { themeData } = this.props.pageContext;

    const css = `
      strong { font-weight: bold; }
      body, input, textarea { margin: 0; font-weight: ${themeDefaultStyle.weight}; font-family: ${
      themeDefaultStyle.fontFamily
    }, Source Sans Pro, sans-serif; }
      h1  { margin: 0; font-size:${NavHead.getFontSize(
        'h1',
        this.props.pageContext.themeData.typography.heading.fontSize,
      )}; text-transform:${this.props.pageContext.themeData.typography.heading.textTransform}; color: ${
      color3.color
    }; font-family: ${themeHeadingStyle.fontFamily}; font-weight: ${themeHeadingStyle.weight}; line-height: ${
      themeHeadingStyle.lineHeight
    }; letter-spacing: ${themeHeadingStyle.letterSpacing}; }
      h2 { margin: 0; font-size:${NavHead.getFontSize(
        'h2',
        this.props.pageContext.themeData.typography.heading.fontSize,
      )}; text-transform:${this.props.pageContext.themeData.typography.heading.textTransform}; color: ${
      color3.color
    }; font-family: ${themeHeadingStyle.fontFamily}; font-weight: ${themeHeadingStyle.weight}; line-height: ${
      themeHeadingStyle.lineHeight
    }; letter-spacing: ${themeHeadingStyle.letterSpacing}; }
      h3 { margin: 0; font-size:${NavHead.getFontSize(
        'h3',
        this.props.pageContext.themeData.typography.heading.fontSize,
      )}; text-transform:${this.props.pageContext.themeData.typography.heading.textTransform}; color: ${
      color3.color
    }; font-family: ${themeHeadingStyle.fontFamily}; font-weight: ${themeHeadingStyle.weight}; line-height: ${
      themeHeadingStyle.lineHeight
    }; letter-spacing: ${themeHeadingStyle.letterSpacing}; }
      h4 { margin: 0; font-size:${NavHead.getFontSize(
        'h4',
        this.props.pageContext.themeData.typography.heading.fontSize,
      )}; text-transform:${this.props.pageContext.themeData.typography.heading.textTransform}; color: ${
      color3.color
    }; font-family: ${themeHeadingStyle.fontFamily}; font-weight: ${themeHeadingStyle.weight}; line-height: ${
      themeHeadingStyle.lineHeight
    }; letter-spacing: ${themeHeadingStyle.letterSpacing}; }
      h5 { margin: 0; font-size:${NavHead.getFontSize(
        'h5',
        this.props.pageContext.themeData.typography.heading.fontSize,
      )}; text-transform:${this.props.pageContext.themeData.typography.heading.textTransform}; color: ${
      color3.color
    }; font-family: ${themeHeadingStyle.fontFamily}; font-weight: ${themeHeadingStyle.weight}; line-height: ${
      themeHeadingStyle.lineHeight
    }; letter-spacing: ${themeHeadingStyle.letterSpacing}; }
      h6 { margin: 0; font-size:${NavHead.getFontSize(
        'h6',
        this.props.pageContext.themeData.typography.heading.fontSize,
      )}; text-transform:${this.props.pageContext.themeData.typography.heading.textTransform}; color: ${
      color3.color
    }; font-family: ${themeHeadingStyle.fontFamily}; font-weight: ${themeHeadingStyle.weight}; line-height: ${
      themeHeadingStyle.lineHeight
    }; letter-spacing: ${themeHeadingStyle.letterSpacing}; }
      h1 span { font-size:${NavHead.getFontSize(
        'h1',
        this.props.pageContext.themeData.typography.heading.fontSize,
      )}; text-transform:${this.props.pageContext.themeData.typography.heading.textTransform}; font-family: ${
      themeHeadingStyle.fontFamily
    }; font-weight: ${themeHeadingStyle.weight}; line-height: ${themeHeadingStyle.lineHeight}; letter-spacing: ${
      themeHeadingStyle.letterSpacing
    }; }
      h2 span { font-size:${NavHead.getFontSize(
        'h2',
        this.props.pageContext.themeData.typography.heading.fontSize,
      )}; text-transform:${this.props.pageContext.themeData.typography.heading.textTransform}; font-family: ${
      themeHeadingStyle.fontFamily
    }; font-weight: ${themeHeadingStyle.weight}; line-height: ${themeHeadingStyle.lineHeight}; letter-spacing: ${
      themeHeadingStyle.letterSpacing
    }; }
      h3 span { font-size:${NavHead.getFontSize(
        'h3',
        this.props.pageContext.themeData.typography.heading.fontSize,
      )}; text-transform:${this.props.pageContext.themeData.typography.heading.textTransform}; font-family: ${
      themeHeadingStyle.fontFamily
    }; font-weight: ${themeHeadingStyle.weight}; line-height: ${themeHeadingStyle.lineHeight}; letter-spacing: ${
      themeHeadingStyle.letterSpacing
    }; }
      h4 span { font-size:${NavHead.getFontSize(
        'h4',
        this.props.pageContext.themeData.typography.heading.fontSize,
      )}; text-transform:${this.props.pageContext.themeData.typography.heading.textTransform}; font-family: ${
      themeHeadingStyle.fontFamily
    }; font-weight: ${themeHeadingStyle.weight}; line-height: ${themeHeadingStyle.lineHeight}; letter-spacing: ${
      themeHeadingStyle.letterSpacing
    }; }
      h6 span { font-size:${NavHead.getFontSize(
        'h6',
        this.props.pageContext.themeData.typography.heading.fontSize,
      )}; text-transform:${this.props.pageContext.themeData.typography.heading.textTransform}; font-family: ${
      themeHeadingStyle.fontFamily
    }; font-weight: normal; line-height: ${themeHeadingStyle.lineHeight}; letter-spacing: ${
      themeHeadingStyle.letterSpacing
    }; }
      h3.footerHeading { font-size:${NavHead.getFontSize(
        'h3',
        this.props.pageContext.themeData.typography.footer.fontSize,
      )}; text-transform:${this.props.pageContext.themeData.typography.footer.textTransform}; font-family: ${
      themeFooterStyle.fontFamily
    }; font-weight: ${themeFooterStyle.weight}; line-height: ${themeFooterStyle.lineHeight}; letter-spacing: ${
      themeFooterStyle.letterSpacing
    }; }
      .storyFull h1, .storyFull h2, .storyFull h3, .storyFull h4, .storyFull span { color: ${color1.color}; }
      span { font-size:${NavHead.getFontSize(
        'p',
        this.props.pageContext.themeData.typography.default.fontSize,
      )}; font-family: ${themeDefaultStyle.fontFamily}; font-weight: ${themeDefaultStyle.weight}; line-height: ${
      themeDefaultStyle.lineHeight
    }; letter-spacing: ${themeDefaultStyle.letterSpacing}; }
      .NavLink a, .NavLink ul a li {font-size:${NavHead.getFontSize(
        'p',
        this.props.pageContext.themeData.typography.navigation.fontSize,
      )}; color: ${color0.color}; font-family: ${themeNavigationStyle.fontFamily}; text-transform:${
      this.props.pageContext.themeData.typography.navigation.textTransform
    }; font-weight: ${themeNavigationStyle.weight}; line-height: ${themeNavigationStyle.lineHeight}; letter-spacing: ${
      themeNavigationStyle.letterSpacing
    }; }
      a { color: ${color0.color}; font-family: ${themeNavigationStyle.fontFamily}; text-transform:${
      this.props.pageContext.themeData.typography.navigation.textTransform
    }; font-weight: ${themeNavigationStyle.weight}; line-height: ${themeNavigationStyle.lineHeight}; letter-spacing: ${
      themeNavigationStyle.letterSpacing
    }; -webkit-user-drag: none; }
      button { font-size:${NavHead.getFontSize(
        'button',
        this.props.pageContext.themeData.typography.button.fontSize,
      )}; text-transform:${this.props.pageContext.themeData.typography.button.textTransform}; font-family: ${
      themeButtonStyle.fontFamily
    }; font-weight: ${themeButtonStyle.weight}; line-height: ${themeButtonStyle.lineHeight}; letter-spacing: ${
      themeButtonStyle.letterSpacing
    }; }
      p, .infoRow {font-size:${NavHead.getFontSize(
        'p',
        this.props.pageContext.themeData.typography.default.fontSize,
      )}; text-transform:${this.props.pageContext.themeData.typography.default.textTransform}; font-family: ${
      themeDefaultStyle.fontFamily
    }; font-weight: ${themeDefaultStyle.weight}; line-height: ${themeDefaultStyle.lineHeight}; letter-spacing: ${
      themeDefaultStyle.letterSpacing
    };  margin-block-start: unset; margin-block-end: unset; }
      p a {color: ${color0.color}; font-size:${NavHead.getFontSize(
      'p',
      this.props.pageContext.themeData.typography.default.fontSize,
    )}; text-transform:${this.props.pageContext.themeData.typography.default.textTransform}; font-family: ${
      themeDefaultStyle.fontFamily
    }; font-weight: ${themeDefaultStyle.weight}; line-height: ${themeDefaultStyle.lineHeight}; letter-spacing: ${
      themeDefaultStyle.letterSpacing
    }; margin-block-start: unset; margin-block-end: unset; }
      .inputLabel p, .inputLabel p span, .inputLabel span { font-size: ${NavHead.getFontSize(
        'p',
        this.props.pageContext.themeData.typography.default.fontSize,
      )}; color: ${color3.color}; font-family: ${themeDefaultStyle.fontFamily}; font-weight: ${
      themeDefaultStyle.weight
    }; line-height: ${themeDefaultStyle.lineHeight}; letter-spacing: ${themeDefaultStyle.letterSpacing}; }
      .grecaptcha-badge { visibility: hidden; }
    .longarticle h1, h1.t { font-size:${NavHead.getFontSizeAlt('h1', themeData.typography.heading.fontSize)}; }
    .longarticle h2, h2.t { font-size:${NavHead.getFontSizeAlt('h2', themeData.typography.heading.fontSize)}; }
    .longarticle h3, h3.t { font-size:${NavHead.getFontSizeAlt('h3', themeData.typography.heading.fontSize)}; }
    .longarticle h4, h4.t { font-size:${NavHead.getFontSizeAlt('h4', themeData.typography.heading.fontSize)}; }
    .longarticle h5, h5.t { font-size:${NavHead.getFontSizeAlt('h5', themeData.typography.heading.fontSize)}; }
    .longarticle h6, h6.t { font-size:${NavHead.getFontSizeAlt('h6', themeData.typography.heading.fontSize)}; }
    .longarticle h1, .longarticle h2, .longarticle h3, .longarticle h4, .longarticle h5, .longarticle h6, h1.t, h2.t, h3.t, h4.t, h5.t, h6.t { line-height: ${
      themeHeadingStyle.lineHeight
    }; letter-spacing: ${themeHeadingStyle.letterSpacing}; font-family: ${
      themeHeadingStyle.fontFamily
    }; font-weight: initial; text-transform: initial; color: #000000; };
    `;

    return (
      <>
        <Helmet>
          <meta charSet="UTF-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=yes" />
          {this.state.faceId && this.state.commType && (
            <meta property={`fb:${this.state.commType}`} content={this.state.faceId} />
          )}
          <link rel="preconnect" href="https://fonts.gstatic.com/" crossOrigin />
          <link rel="preconnect" href="https://www.gstatic.com" crossOrigin />
          {hasInstagram && <link rel="preconnect" href="https://scontent.cdninstagram.com" crossOrigin />}
          {hasTwitter && <link rel="preconnect" href="https://ton.twimg.com" crossOrigin />}
          {hasFacebook && <link rel="preconnect" href="https://scontent.fhel1-1.fna.fbcdn.net" crossOrigin />}
          {hasFacebook && <link rel="preconnect" href="https://www.facebook.com" crossOrigin />}
          {hasFacebook && <link rel="preconnect" href="https://static.xx.fbcdn.net" crossOrigin />}
          <html lang={this.props.pageContext.metaLanguage} />
          {this.state.head}
          <title>{this.state.title}</title>
          {this.state.description && <meta name="description" content={this.state.description} />}
          {this.props.href && <meta name="og:url" content={this.props.href} />}
          {this.state.description && <meta property="og:description" content={this.state.description} />}
          {this.state.metaTitle && <meta property="og:title" content={this.state.metaTitle} />}
          {this.state.previewImage && <meta property="og:image" content={this.state.previewImage} />}
          {this.props.pageContext.hasArticles && (
            <link rel="alternate" type="application/rss+xml" title={this.state.title} href="/rss.xml" />
          )}
          {this.props.pageContext.hasContact && (
            <script
              src={`https://www.google.com/recaptcha/api.js?render=${process.env.RECAPTCHA_SITE_KEY}`}
              async
              defer
            />
          )}
        </Helmet>
        <style>{css}</style>
        <IEWarning />
        <NavigationBar
          noInfoRow={this.props.noInfoRow}
          isScrolling={this.props.isScrolling}
          pageContext={this.props.pageContext}
        />
      </>
    );
  }
}

export default NavHead;
